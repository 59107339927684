import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import "./Login.css"
import axios from "axios"
import { useHistory } from "react-router"
import RemyLogo from "./Asset/remy_logo.jpg"


const Login = () => {

    const [JsonData, setJsonData] = useState()
    const [UserName, setUsername] = useState({
        guestUser: "",
        email: "guest@user.com"
    })
    const [error, setError] = useState("");

    const HandleChange = (e) => {
        const { name, value } = e.target
        setUsername({
            ...UserName,
            [name]: value
        })
    }


        if (localStorage.getItem("AuthData") === null) {
            if (window.location.href.includes("token&code=")) {
                var Url = window.location.href.split("token&code=")[1];
                var ExpireTime = Url.split("&token_type=")[1];
                var TokenExpireTimeWithScope = ExpireTime.split("&expires_in=")[1];
                var TokenExpireTime = TokenExpireTimeWithScope.split("&scope=")[0];
                var Code = Url.split("&access_token=")[0];
                var FillUrl = Url.split("&token_type=")[0];
                var Token = FillUrl.split("&access_token=")[1];
                const AuthData = {
                    TokenExpireTime: TokenExpireTime,
                    Code: Code,
                    Token: Token
                } 
                axios.post(`https://remycointreau.my.idaptive.app/OAuth2/UserInfo/TDD_app`, null, { headers: { Authorization: `Bearer ${Token}` } })
                    .then((response) => {
                        if (response.status === 200) {                            
                            localStorage.setItem("AuthData", JSON.stringify(response.data));
                            window.location.pathname = "/";
                        }
                    })
                    .catch((e) => {
                        console.log("error is",e);
                    })
            }
        } 


    const HandleSubmit = (e) => {
        e.preventDefault();
        if (UserName.guestUser.length !== 0) {
            if (UserName.guestUser.match("guest") || UserName.guestUser.match("GUEST")) {
                setError("");
                setUsername({
                    guestUser: "",
                    email: "guest@user.com"
                })
                
                localStorage.setItem("guest", JSON.stringify(UserName))
                window.location.pathname = "/";

            } else {
                setError("");                
                window.location.href = `https://remycointreau.my.idaptive.app/OAuth2/Authorize/TDD_app?client_id=f76acfa8-8cb6-4257-9ce2-7db7d229dcc0&scope=openid%20profile%20email&response_type=code token&redirect_uri=https://remyvmexperience.com/login`;
            }
        } else {
            setError("Please enter your login name.");
        }
    }
    return (
        <>
            <div className="login">
                <div className="loginpage">
                    <div className="login-form">
                        <img src={RemyLogo} className="mb-3" />
                        <p className="mb-0 text-danger">{error}</p>
                        <div className="d-flex align-items-center w-100 justify-content-center">
                            <label>Name: </label>
                            <input type="text" name="guestUser" className="w-100 m-2" value={UserName.guestUser} onChange={(e) => { HandleChange(e) }} required={true} />
                        </div>
                        <Button variant="primary" type="submit" className="login-btn mt-4" onClick={(e) => HandleSubmit(e)}>
                            LOGIN
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login
