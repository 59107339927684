                                                                                                                                                                                                                                                                                                         import axios from "axios"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import RemyLogo from "./Asset/remy_logo.jpg"

const AppUser = () => {

    const [userData, setUserData] = useState()
    const history = useHistory()


    useEffect(() => {
        if (window.location.href.includes("#responseType") || window.location.href.includes("?")) {
            var Url = window.location.href.split("token&code=")[1];
            var ExpireTime = Url.split("&token_type=")[1];
            var TokenExpireTimeWithScope = ExpireTime.split("&expires_in=")[1];
            var TokenExpireTime = TokenExpireTimeWithScope.split("&scope=")[0];                                                          
            var Code = Url.split("&access_token=")[0];
            var FillUrl = Url.split("&token_type=")[0];
            var Token = FillUrl.split("&access_token=")[1];
        
            axios.post(`https://remycointreau.my.idaptive.app/OAuth2/UserInfo/TDD_app`, null, { headers: { Authorization: `Bearer ${Token}` } })
                .then((response) => {
                    if (response.status === 200) {
                
                        localStorage.setItem("AuthData",JSON.stringify(response.data)) 
                        window.location.href = "app://LoginSuccess?data="+JSON.stringify(response.data);
                    }
                })
                .catch((e) => {
                    localStorage.removeItem("AuthData")
                    window.location.href = "app://LoginFail";
                
                })
        } else {
            if (window.location.href.includes("#") || window.location.href.includes("?")) {
                
                window.location.href = "app://LoginFail";
            }
        }                                                                                                                                                                                                                                                 
    }, [])

    useEffect(() => {

    }, [])
    return (
        <div className="login">
        <div className="loginpage">
            <div className="login-form">
                <img src={RemyLogo} className="mb-3" />
                <h2>Loading...</h2>
            </div>
        </div>
    </div>
    )
}
export default AppUser                                                                