import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import './App.css';
import AppUser from "./AppUser";
import Home from "./Home";
import Login from './Login';

const RedirectRoute = ({ component: Component, ...rest }) => {


  useEffect(() => {
    if (localStorage.getItem("AuthData") !== null) {
      if (localStorage.getItem("guest") !== null) {
        localStorage.removeItem("guest");
      }
    } else {
      if (localStorage.getItem("guest") !== null) {
        localStorage.removeItem("AuthData");
      }
    }
  }, [])


  return (
    <Route {...rest} render={props => (
      (localStorage.getItem("guest") || localStorage.getItem("AuthData")) ?
        <Redirect to="/" />
        :
        <Component {...props} />
    )} />
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {


  useEffect(() => {
    if (localStorage.getItem("AuthData") !== null) {
      if (localStorage.getItem("guest") !== null) {
        localStorage.removeItem("guest");
      }
    } else {
      if (localStorage.getItem("guest") !== null) {
        localStorage.removeItem("AuthData");
      }
    }
  }, []);


  return (
    <Route {...rest} render={props => (
      (localStorage.getItem("guest") || localStorage.getItem("AuthData")) ?
        <Component {...props} />
        :
        <Redirect to="/login" />
    )} />
  );
}

function App() {

  return (
    <Router>
      <div className="App">
        <Switch>
          <PrivateRoute exact={true} path="/" component={Home} />
          <RedirectRoute path="/login" component={Login} />
          <Route path="/app/:user" component={AppUser} />
          <Route path="/app" component={AppUser} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
