import React, { useEffect, useState } from "react"
import Unity, { UnityContext } from "react-unity-webgl"
import { Button, Col, Container, Modal, Row } from "react-bootstrap"
import RemyLogo from "./Asset/remy_logo.jpg"

const unityContext = new UnityContext({
    loaderUrl: "Build/WebBuild.loader.js",
    dataUrl: "Build/WebBuild.data",
    frameworkUrl: "Build/WebBuild.framework.js",
    codeUrl: "Build/WebBuild.wasm",
});
window.ctx = unityContext

const Home = () => {
    const [modalShow, setModalShow] = useState(true);

    const [progress, setProgress] = useState();

    useEffect(() => {
        unityContext.on("progress", setProgress);
    }, [])

    return (
        <div>

            {/* <MydModalWithGrid show={modalShow} onHide={() => setModalShow(false)} /> */}
            {progress !== 1 &&
            <Modal className="modalHome" show={modalShow} aria-labelledby="contained-modal-title-vcenter" centered>
                {/* <Modal.Body className="show-grid d-flex flex-column justify-content-evenly"> */}
                <Modal.Body className="show-grid">
                    <Container>
                        <div className="logoimagemodal">
                            <img src={RemyLogo} />
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div className="progress w-100" style={{ height: "24px", position: "relative" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${progress * 100}%`, backgroundColor: "brown" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                        <div className="position-relative position-absolute" style={{ top: "1px", left: "44%", fontSize: "15px", color: "black" }}>Loading</div>
                    </div>
                </Modal.Footer>
            </Modal>}
            <Unity unityContext={unityContext} className="unity" style={{ background: "#231F20" }} />
        </div>
    )
}

export default Home